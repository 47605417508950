// eslint-disable-next-line import/prefer-default-export
export const StorageTypes = {
  SET_ITEM: 'SET_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  SET_GLOBAL_ITEM: 'SET_CLOBAL_ITEM',
  REMOVE_GLOBAL_ITEM: 'REMOVE_GLOBAL_ITEM',
  CLEAR: 'CLEAR',
  ACTION_ON_GLOBAL_ITEMS_WITH_PREFIX: 'ACTION_ON_GLOBAL_ITEMS_WITH_PREFIX',
  ACTION_ON_ITEMS_WITH_PREFIX: 'ACTION_ON_ITEMS_WITH_PREFIX',
  STORAGE_REHYDRATE: 'STORAGE_REHYDRATE',
}
