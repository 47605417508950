/* eslint-disable react/jsx-props-no-spreading */
import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'

function matomoHOC(Component) {
  return function WrappedComponent(props) {
    const { trackPageView } = useMatomo()
    return <Component {...props} trackPageView={trackPageView} />
  }
}

export default matomoHOC
