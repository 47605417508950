/* eslint-disable no-restricted-globals */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import * as serviceWorker from './serviceWorker'
import App from './App'
import configureMattermostStore from './store/configureMattermostStore'

const store = configureMattermostStore()

const matomoInstance = createInstance({
  urlBase: 'https://stats.kvl.fi',
  siteId: 18,
  disabled: location.hostname !== 'some.kohdataan.fi',
})

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <MatomoProvider value={matomoInstance}>
          <App />
        </MatomoProvider>
      </Router>
    </Provider>,
    document.getElementById('root')
  )
}

render()
store.subscribe(render)

serviceWorker.unregister()
