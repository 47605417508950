export default {
  Siniset: '#0000FF',
  Vaaleansiniset: '#ADD8E6',
  Tummansiniset: '#00008B',
  Haaleansiniset: '#A9D5E8',
  Kirkkaansiniset: '#0505FF',
  Merensiniset: '#15BEF0',
  Sähkönsiniset: '#0000CD',
  Taivaansiniset: '#87CEED',
  Laivastonsiniset: '#000080',
  Syvänsiniset: '#0E0A70',
  Yönsiniset: '#00009C',
  Teräksensiniset: '#4682B4',
  Lemmikinsiniset: '#7997B3',
  Indigonsiniset: '#4B0082',
  Jäänsiniset: '#E0FFFF',
  Punaiset: '#FF0000',
  Vaaleanpunaiset: '#FFC0CB',
  Tummanpunaiset: '#8B0000',
  Haaleanpunaiset: '#ED5353',
  Kirkkaanpunaiset: '#FF1414',
  Viininpunaiset: '#A31C1C',
  Ruosteenpunaiset: '#B7410E',
  Syvänpunaiset: '#8F0303',
  Joulunpunaiset: '#FC0303',
  Tulenpunaiset: '#FF2400',
  Kirsikanpunaiset: '#DE3163',
  Unikonpunaiset: '#EB0707',
  Tiilenpunaiset: '#B22222',
  Omenanpunaiset: '#A80A0A',
  Keltaiset: '#FFFF00',
  Vaaleankeltaiset: '#F7F74F',
  Tummankeltaiset: '#FFE100',
  Haaleankeltaiset: '#F5F578',
  Kirkkaankeltaiset: '#FFF700',
  Kullankeltaiset: '#FFD700',
  Auringonkeltaiset: '#FFE600',
  Sinapinkeltaiset: '#f5D11D',
  Syvänkeltaiset: '#FFEA00',
  Oljenkeltaiset: '#F5E56E',
  Okrankeltaiset: '#D18B00',
  Leinikinkeltaiset: '#FCF10F',
  Vihreät: '#008000',
  Vaaleanvihreät: '#90EE90',
  Tummanvihreät: '#006400',
  Haaleanvihreät: '#98FB98',
  Kirkkaanvihreät: '#05FF05',
  Metsänvihreät: '#228B22',
  Ruohonvihreät: '#7CFC00',
  Mintunvihreät: '#CFFFE5',
  Limenvihreät: '#32CD32',
  Syvänvihreät: '#053614',
  Merenvihreät: '#2E8B57',
  Oliivinvihreät: '#6B8E23',
  Sinivihreät: '#008080',
  Ruskeat: '#804201',
  Vaaleanruskeat: '#C49058',
  Tummanruskeat: '#4A2804',
  Haaleanruskeat: '#BA9670',
  Kullanruskeat: '#A65805',
  Suklaanruskeat: '#4F291E',
  Kuparinruskeat: '#B87333',
  Kastanjanruskeat: '#800000',
  Pronssinruskeat: '#8C7853',
  Pähkinänruskeat: '#82270C',
  Punaruskeat: '#702A16',
  Toffeenruskeat: '#F2CB55',
  Hiekanruskeat: '#524729',
  Siirapinruskeat: '#472001',
  Oranssit: '#FFA500',
  Vaaleanoranssit: '#FFBF00',
  Tummanoranssit: '#FF7700',
  Haaleanoranssit: '#FADD73',
  Kirkkaanoranssit: '#FFC803',
  Violetit: '#C8A2C8',
  Vaaleanvioletit: '#FAD9FA',
  Tummanvioletit: '#AD07AD',
  Haaleanvioletit: '#F5E9F5',
  Kirkkaanvioletit: '#FF00FF',
  Harmaat: '#808080',
  Vaaleanharmaat: '#D3D3D3',
  Tummanharmaat: '#6E6D6D',
  Haaleanharmaat: '#F0F0F0',
  Kirkkaanharmaat: '#D9D7D7',
  Hopeanharmaat: '#C0C0C0',
  Syvänharmaat: '#363535',
  Mustat: '#000000',
  Sysimustat: '#0D0D0D',
  Yönmustat: '#1F1D1D',
  Lakritsinmustat: '#2E2222',
  Valkoiset: '#FFFFFF',
  Luonnonvalkoiset: '#F2F2E9',
  Lumenvalkoiset: '#FFFAFA',
  Liidunvalkoiset: '#FFFEF9',
  Kermanvalkoiset: '#FFFFED',
  Turkoosit: '#40E0D0',
  Vaaleanturkoosit: '#7FFFD4',
  Tummanturkoosit: '#00B2B5',
  Haaleanturkoosit: '#AFEEEE',
  Kirkkaanturkoosit: '#05F1F5',
  Syvänturkoosit: '#00A7D1',
  Keskiyönsiniset: '#000D61',
  Mustikansiniset: '#4B6E9C',
  Farkunsiniset: '#2A469C',
  Musteensiniset: '#000F2E',
  Safiirinsiniset: '#0F52BA',
  Harmaansiniset: '#465F75',
  Kissankellonsiniset: '#7767C2',
  Ruiskukansiniset: '#6495ED',
  Sinivioletit: '#8A2BE2',
  Terrakotanpunaiset: '#BA4D36',
  Punamullanpunaiset: '#801818',
  Rubiininpunaiset: '#910101',
  Ruusunpunaiset: '#E04F82',
  Ketsupinpunaiset: '#D10606',
  Paloautonpunaiset: '#C91602',
  Vadelmanpunaiset: '#C40441',
  Mansikanpunaiset: '#DE0404',
  Puolukanpunaiset: '#C90028',
  Verenpunaiset: '#960202',
  Ferrarinpunaiset: '#F50C0C',
  Aniliininpunaiset: '#BD0488',
  Marjapuuronpunaiset: '#E36291',
  Mesimarjanpunaiset: '#94032E',
  Chilinpunaiset: '#C20C0C',
  Laventelinpunaiset: '#FFF0F5',
  Luumunpunaiset: '#DDA0DD',
  Karmiininpunaiset: '#DC143C',
  Korallinpunaiset: '#FF7F50',
  Tomaatinpunaiset: '#FF6347',
  Herukanpunaiset: '#D40000',
  Persikanpunaiset: '#FFDAB9',
  Oranssinpunaiset: '#FF4500',
  Greipinoranssit: '#EBD621',
  Appelsiininoranssit: '#FF6C03',
  Mandariininoranssit: '#FF5303',
  Voikukankeltaiset: '#F0E130',
  Rikinkeltaiset: '#F1DD38',
  Currynkeltaiset: '#CCA01D',
  Banaaninkeltaiset: '#FFE135',
  Rentukankeltaiset: '#FFF200',
  Sitruunankeltaiset: '#FFF44F',
  Voinkeltaiset: '#FBFF80',
  Vehnänkeltaiset: '#F5DEB3',
  Ruskeankeltaiset: '#875F00',
  Vihreänkeltaiset: '#ADFF2F',
  Keväänvihreät: '#00FF44',
  Keltaisenvihreät: '#9ACD32',
  Smaragdinvihreät: '#064F40',
  Myrkynvihreät: '#A3B81C',
  Havunvihreät: '#1F3D0C',
  Sammaleenvihreät: '#474F0D',
  Armeijanvihreät: '#2F3616',
  Harmaanvihreät: '#575E3A',
  Omenanvihreät: '#69CB15',
  Päärynänvihreät: '#A0C734',
  Tillinvihreät: '#61960C',
  Apilanvihreät: '#288521',
  Kuusenvihreät: '#325C33',
  Männynvihreät: '#53782A',
  Koivunvihreät: '#659C13',
  Vedenvihreät: '#03BB85',
  Kaakaonruskeat: '#35281E',
  Seepianruskeat: '#704214',
  Maitokahvinruskeat: '#C8AD90',
  Mahonginruskeat: '#420D09',
  Pahvinruskeat: '#A18550',
  Kaarnanruskeat: '#1A0F0B',
  Kävynruskeat: '#573314',
  Piparkakunruskeat: '#9F5529',
  Ruosteenruskeat: '#B7410E',
  Harmaanruskeat: '#756452',
  Kanelinruskeat: '#7A5000',
  Hiilenharmaat: '#393D3C',
  Hiirenharmaat: '#6E6E6E',
  Elefantinharmaat: '#786C6C',
  Grafiitinharmaat: '#A2A5AA',
  Betoninharmaat: '#D2D1CD',
  Siniharmaat: '#7693A6',
  Helmenharmaat: '#E3E3E3',
  Savunharmaat: '#888C8F',
  Hylkeenharmaat: '#8D9994',
  Teräksenharmaat: '#656566',
  Armeijanharmaat: '#838385',
  Lyijynharmaat: '#687378',
  Myrskynharmaat: '#78787F',
  Loskanharmaat: '#E3DFDA',
  Jäänharmaat: '#E0E0DE',
  Punaharmaat: '#856D6D',
  Tuhkanharmaat: '#BDBAAA',
  Vihreänharmaat: '#788878',
  Maantienharmaat: '#A1927A',
  Tinanharmaat: '#7A7A78',
  Jäkälänharmaat: '#C4CFC8',
  Pulunharmaat: '#858585',
  Pajunkissanharmaat: '#D9D9D9',
  Ruskeanharmaat: '#554D42',
  Noenmustat: '#151B21',
  Korpinmustat: '#141A16',
  Salmiakinmustat: '#19241D',
}
