/* eslint-disable import/prefer-default-export */
// User
export const ADD_USER_TO_STATE = 'KOHDATAAN_ADD_USER_TO_STATE'
export const UPDATE_USER = 'KOHDATAAN_UPDATE_USER'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE'
export const SIGNUP_SIGNIN_FAILURE = 'KOHDATAAN_SIGNUP_SIGNIN_FAILURE'
export const GET_USER_FAILURE = 'KOHDATAAN_GET_USER_FAILURE'
export const UPDATE_USER_FAILURE = 'KOHDATAAN_UPDATE_USER_FAILURE'
export const GET_USER_INTERESTS = 'KOHDATAAN_GET_USER_INTERESTS'
export const USER_LOGIN_FAILURE = 'KOHDATAAN_USER_LOGIN_FAILURE'
export const RESTORE_USER = 'KOHDATAAN_RESTORE_USER'

// Interest
export const GET_INTERESTS = 'KOHDATAAN_GET_INTERESTS'

// Channels
export const START_INVITATION_FETCHING = 'KOHDATAAN_START_INVITATION_FETCHING'
export const INVITATION_FETCHING_READY = 'KOHDATAAN_INVITATION_FETCHING_READY'
export const GET_CHANNEL_INVITATIONS = 'KOHDATAAN_GET_CHANNEL_INVITATIONS'
export const RESET_CHANNEL_INVITATIONS = 'KOHDATAAN_RESET_CHANNEL_INVITATIONS'
export const GET_CHANNEL_INVITATION_MEMBERS =
  'KOHDATAAN_GET_CHANNEL_INVITATION_MEMBERS'

// Friends
export const START_FRIENDS_FETCHING = 'KOHDATAAN_START_FRIENDS_FETCHING'
export const FRIENDS_FETCHING_READY = 'KOHDATAAN_FRIENDS_FETCHING_READY'

// Mattermost
export const STORE_REHYDRATION_FAILED = 'KOHDATAAN_STORE_REHYDRATION_FAILED'

// Root
export const START_ROOT_LOADING = 'KOHDATAAN_START_ROOT_LOADING'
export const ROOT_LOADING_READY = 'KOHDATAAN_ROOT_LOADING_READY'
export const SET_COORDINATES = 'KOHDATAAN_SET_COMPONENT_COORDINATES'
